import { render, staticRenderFns } from "./SupportBlock.vue?vue&type=template&id=3987f458&scoped=true&"
import script from "./SupportBlock.js?vue&type=script&lang=js&"
export * from "./SupportBlock.js?vue&type=script&lang=js&"
import style0 from "./SupportBlock.css?vue&type=style&index=0&id=3987f458&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3987f458",
  null
  
)

export default component.exports